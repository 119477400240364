/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          meta: {
            title: 'Home',
            canonical: '/'
          },
          component: () => import(/* webpackChunkName: "home" */ './views/Home/Home.vue')
        },
        {
          path: '/formacao',
          name: 'Formacao',
          meta: {
            title: 'Formação',
            canonical: '/formacao'
          },
          component: () => import(/* webpackChunkName: "formacao" */ './views/Doutor/Doutor.vue')
        },
        {
          path: '/tipo_dor',
          name: 'doenca',
          meta: {
            title: 'Doenca',
            canonical: '/tipo_dor'
          },
          component: () => import(/* webpackChunkName: "dor" */ './views/Doencas/Doencas.vue')
        },
        {
          path: '/tipo_dor_detalhe',
          name: 'doenca_detalhe',
          meta: {
            title: 'Doenca',
            canonical: '/tipo_dor_detalhe'
          },
          component: () => import(/* webpackChunkName: "artigo_dor" */ './views/Doencas/DoencaDetalhes.vue')
        },
        {
          path: '/tratamento',
          name: 'Tratamento',
          meta: {
            title: 'Tratamento',
            canonical: '/tratamento'
          },
          component: () => import(/* webpackChunkName: "tratamento" */ './views/Tratamento/ListarArtigos.vue')
        },
        {
          path: '/blog',
          name: 'blog',
          meta: {
            title: 'Blog',
            canonical: '/blog'
          },
          component: () => import(/* webpackChunkName: "log" */ './views/Blog/Blog.vue')
        },
        {
          path: '/atendimento',
          name: 'Atendimento',
          meta: {
            title: 'Atendimento',
            canonical: '/atendimento' // URL canônica para a página atendimento
          },
          component: () => import(/* webpackChunkName: "atendimento" */ './views/Atendimento/Atendimento.vue')
        },
        {
          path: '/contato',
          name: 'Contato',
          meta: {
            title: 'Contato',
            canonical: '/contato'
          },
          component: () => import(/* webpackChunkName: "contato" */ './views/Contato/Contato.vue')
        },
        {
          path: '/na_midia',
          name: 'NaMidia',
          meta: {
            title: 'Na Midia',
            canonical: '/na_midia' // URL canônica para a página na_midia
          },
          component: () => import(/* webpackChunkName: "na_midia" */ './views/NaMidia/NaMidia.vue')
        },
        {
          path: '/na_midia/artigo/:blog_id',
          name: 'AtigoNaMidia',
          meta: {
            title: 'Na Midia',
            canonical: '/na_midia/artigo/:blog_id' // URL canônica para a página artigo da mídia
          },
          component: () => import(/* webpackChunkName: "na_midia_artigo" */ './views/NaMidia/Artigo.vue')
        },
        {
          path: '/livro',
          name: 'Livro',
          meta: {
            title: 'Livro',
            canonical: '/livro' // URL canônica para a página livro
          },
          component: () => import(/* webpackChunkName: "na_midia_artigo" */ './views/Livro/Livro.vue')
        }

      ]
    },
    {
      path: '*',
      name: '404',
      meta: { title: '404' },
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    }
  ]
})


router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title + ' - Dra. Amelie' : 'Dra. Amelie';
  next();
});

router.afterEach((to) => {
  const linkCanonical = document.querySelector("link[rel='canonical']");
  // const canonicalUrl = window.location.pathname||to.meta?.canonical;
  // const canonicalUrl = window.location.origin + (to.meta?.canonical || window.location.pathname);
  const canonicalUrl = window.location.origin + ( window.location.pathname||to.meta?.canonical);


  if (linkCanonical) {
    linkCanonical.setAttribute("href", canonicalUrl);
  } else {
    const link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", canonicalUrl);
    document.head.appendChild(link);
  }
});
export default router
